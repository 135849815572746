<template>
  <svg
    id="Component_322_1"
    data-name="Component 322 – 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="36"
    height="36"
    viewBox="0 0 36 36"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="-0.016"
        y1="0.5"
        x2="0.958"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#fff9df" />
        <stop offset="0.593" stop-color="#ffe177" />
        <stop offset="1" stop-color="#feb137" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="0.157"
        y1="0.5"
        x2="1.093"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#ffe177" />
        <stop offset="1" stop-color="#feb137" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-7"
        x1="-1.068"
        y1="0.5"
        x2="1.06"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#b0e7c9" />
        <stop offset="0.593" stop-color="#46cc8d" />
        <stop offset="1" stop-color="#0ab69f" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-12"
        x1="-0.074"
        y1="0.5"
        x2="1.127"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#fe99a0" />
        <stop offset="0.593" stop-color="#fe646f" />
        <stop offset="1" stop-color="#e41f2d" />
      </linearGradient>
    </defs>
    <rect id="bg" width="36" height="36" rx="5" fill="#fff6ed" />
    <g id="_003-schedule" data-name="003-schedule" transform="translate(5 6)">
      <path
        id="Path_3739"
        data-name="Path 3739"
        d="M23.547,99.194H1.572A1.572,1.572,0,0,1,0,97.622V79.971A1.572,1.572,0,0,1,1.572,78.4H23.547a1.572,1.572,0,0,1,1.572,1.572V97.622A1.572,1.572,0,0,1,23.547,99.194Z"
        transform="translate(0 -75)"
        fill="#ebf5fc"
      />
      <path
        id="Path_3740"
        data-name="Path 3740"
        d="M23.547,78.4H1.572A1.572,1.572,0,0,0,0,79.971v4.754H23.1v12.9a1.572,1.572,0,0,1-1.572,1.572h2.021a1.572,1.572,0,0,0,1.572-1.572V79.971A1.572,1.572,0,0,0,23.547,78.4Z"
        transform="translate(0 -75)"
        fill="#d3dcfb"
      />
      <path
        id="Path_3741"
        data-name="Path 3741"
        d="M25.119,83.494H0V79.971A1.572,1.572,0,0,1,1.572,78.4H23.547a1.572,1.572,0,0,1,1.572,1.572Z"
        transform="translate(0 -75)"
        fill="#ff4155"
      />
      <path
        id="Path_3742"
        data-name="Path 3742"
        d="M98.692,78.4V81.05a1.6,1.6,0,0,1-3.192,0V78.4Z"
        transform="translate(-90.796 -75.003)"
        fill="#ff9421"
      />
      <path
        id="Path_3743"
        data-name="Path 3743"
        d="M242.233,78.4V81.05a1.6,1.6,0,0,1-3.193,0V78.4Z"
        transform="translate(-227.266 -75.003)"
        fill="#ff9421"
      />
      <path
        id="Path_3744"
        data-name="Path 3744"
        d="M385.783,78.4V81.05a1.6,1.6,0,0,1-3.193,0V78.4Z"
        transform="translate(-363.746 -75.003)"
        fill="#ff9421"
      />
      <path
        id="Path_3745"
        data-name="Path 3745"
        d="M440.028,78.4h-1.971a1.572,1.572,0,0,1,1.572,1.572v3.522H441.6V79.971A1.572,1.572,0,0,0,440.028,78.4Z"
        transform="translate(-416.481 -75)"
        fill="#ea3535"
      />
      <g id="Group_4183" data-name="Group 4183" transform="translate(3.893 0)">
        <g id="Group_4180" data-name="Group 4180">
          <path
            id="Path_3746"
            data-name="Path 3746"
            d="M82.237,14.457V10.989a1.6,1.6,0,0,0-3.192,0v3.468a1.6,1.6,0,0,0,3.192,0Z"
            transform="translate(-79.045 -9.393)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Path_3747"
            data-name="Path 3747"
            d="M99.562,9.392a1.591,1.591,0,0,0-.616.123,1.6,1.6,0,0,1,.98,1.473v3.468a1.6,1.6,0,0,1-.98,1.473,1.6,1.6,0,0,0,2.212-1.473V10.988A1.6,1.6,0,0,0,99.562,9.392Z"
            transform="translate(-97.966 -9.392)"
            fill="url(#linear-gradient-2)"
          />
        </g>
        <g id="Group_4181" data-name="Group 4181" transform="translate(7.07)">
          <path
            id="Path_3748"
            data-name="Path 3748"
            d="M225.786,14.457V10.989a1.6,1.6,0,1,0-3.192,0v3.468a1.6,1.6,0,1,0,3.192,0Z"
            transform="translate(-222.594 -9.393)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Path_3749"
            data-name="Path 3749"
            d="M243.111,9.392a1.591,1.591,0,0,0-.616.123,1.6,1.6,0,0,1,.98,1.473v3.468a1.6,1.6,0,0,1-.98,1.473,1.6,1.6,0,0,0,2.212-1.473V10.988A1.6,1.6,0,0,0,243.111,9.392Z"
            transform="translate(-241.515 -9.392)"
            fill="url(#linear-gradient-2)"
          />
        </g>
        <g id="Group_4182" data-name="Group 4182" transform="translate(14.141)">
          <path
            id="Path_3750"
            data-name="Path 3750"
            d="M369.335,14.457V10.989a1.6,1.6,0,1,0-3.192,0v3.468a1.6,1.6,0,1,0,3.192,0Z"
            transform="translate(-366.143 -9.393)"
            fill="url(#linear-gradient)"
          />
          <path
            id="Path_3751"
            data-name="Path 3751"
            d="M386.66,9.392a1.591,1.591,0,0,0-.616.123,1.6,1.6,0,0,1,.98,1.473v3.468a1.6,1.6,0,0,1-.98,1.473,1.6,1.6,0,0,0,2.212-1.473V10.988A1.6,1.6,0,0,0,386.66,9.392Z"
            transform="translate(-385.064 -9.392)"
            fill="url(#linear-gradient-2)"
          />
        </g>
      </g>
      <g id="Group_4184" data-name="Group 4184" transform="translate(2.576 11.569)">
        <path
          id="Path_3752"
          data-name="Path 3752"
          d="M55.826,248.039H53.589a1.284,1.284,0,0,1-1.284-1.284v-1.188a1.284,1.284,0,0,1,1.284-1.284h2.237a1.284,1.284,0,0,1,1.284,1.284v1.188A1.284,1.284,0,0,1,55.826,248.039Z"
          transform="translate(-52.305 -244.283)"
          fill="url(#linear-gradient-7)"
        />
        <path
          id="Path_3753"
          data-name="Path 3753"
          d="M209.746,248.039h-2.237a1.284,1.284,0,0,1-1.284-1.284v-1.188a1.284,1.284,0,0,1,1.284-1.284h2.237a1.284,1.284,0,0,1,1.284,1.284v1.188A1.284,1.284,0,0,1,209.746,248.039Z"
          transform="translate(-198.644 -244.283)"
          fill="url(#linear-gradient-7)"
        />
        <path
          id="Path_3754"
          data-name="Path 3754"
          d="M363.666,248.039h-2.237a1.284,1.284,0,0,1-1.284-1.284v-1.188a1.284,1.284,0,0,1,1.284-1.284h2.237a1.284,1.284,0,0,1,1.284,1.284v1.188A1.284,1.284,0,0,1,363.666,248.039Z"
          transform="translate(-344.983 -244.283)"
          fill="url(#linear-gradient-7)"
        />
      </g>
      <g id="Group_4185" data-name="Group 4185" transform="translate(2.576 17.748)">
        <path
          id="Path_3755"
          data-name="Path 3755"
          d="M55.826,373.493H53.589a1.284,1.284,0,0,1-1.284-1.284v-1.188a1.284,1.284,0,0,1,1.284-1.284h2.237a1.284,1.284,0,0,1,1.284,1.284v1.188A1.284,1.284,0,0,1,55.826,373.493Z"
          transform="translate(-52.305 -369.737)"
          fill="url(#linear-gradient-7)"
        />
        <path
          id="Path_3756"
          data-name="Path 3756"
          d="M209.746,373.493h-2.237a1.284,1.284,0,0,1-1.284-1.284v-1.188a1.284,1.284,0,0,1,1.284-1.284h2.237a1.284,1.284,0,0,1,1.284,1.284v1.188A1.284,1.284,0,0,1,209.746,373.493Z"
          transform="translate(-198.644 -369.737)"
          fill="url(#linear-gradient-7)"
        />
      </g>
      <path
        id="Path_3757"
        data-name="Path 3757"
        d="M364.792,362.347l.676-.676a.986.986,0,1,0-1.394-1.394l-.676.676-.676-.676a.986.986,0,1,0-1.394,1.394l.676.676-.676.676a.986.986,0,0,0,1.394,1.394l.676-.676.676.676a.986.986,0,0,0,1.394-1.394Z"
        transform="translate(-343.257 -342.72)"
        fill="url(#linear-gradient-12)"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped>
#Component_58_3 {
  #Rectangle_2631 {
    fill: var(--footer-icon);
  }
}
#Component_58_3:hover {
  #Rectangle_2631 {
    fill: var(--white);
  }
  #Icon_awesome-facebook-f {
    fill: var(--primary-green);
  }
}
</style>
